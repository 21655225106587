<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Rank each of the following molecules in terms of their net dipole moment.</p>

      <drag-to-rank-input
        v-model="inputs.input1"
        :items="items"
        class="mb-1 pl-8"
        style="max-width: 400px"
        prepend-text="Largest"
        append-text="Smallest"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import DragToRankInput from '@/tasks/components/inputs/DragToRankInput';

export default {
  name: 'Question57',
  components: {
    ChemicalLatex,
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
      },
      items: ['$\\ce{H2Se}$', '$\\ce{H2Te}$', '$\\ce{H2O}$', '$\\ce{H2S}$'],
    };
  },
};
</script>
